import { useEffect, useMemo, useState } from 'react';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const usePriceSerieCombinationPreview = ({
  productSlug,
  values,
  shouldFetch = true
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [conversionStatistics, setConversionStatistics] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setErrors([]);
      setDataPoints([]);
      setConversionStatistics({});

      const url = `${BASE_URL}/prices/series/v2/${productSlug}/combine/preview/`;

      const selectedPrices = values.selected_prices.map(({ id }) => id);
      const operations = values.selected_prices.map(
        ({ operation }) => operation
      );

      const params = {
        selected_prices: selectedPrices,
        operations: operations,
        operation: values.operation,
        target_unit: values.unit,
        target_currency: values.currency,
        target_format: values.format,
        target_frequency: values.frequency
      };

      // will turn `operations[]=ADD&operations[]=ADD` into `operations[0]=ADD&operations[1]=ADD`
      const additionalConfig = {
        paramsSerializer: {
          indexes: true
        }
      };

      const { data, errors, success } = await requestSdk(() =>
        get(url, params, additionalConfig)
      );

      if (success) {
        setDataPoints(data?.data_points);
        setConversionStatistics(data?.conversion_statistics);
      }

      if (errors.length > 0) {
        const filteredErrors = errors.filter(
          error => error.code !== 'required'
        );
        setErrors(
          filteredErrors.length > 0
            ? filteredErrors
            : [{ message: 'No data to show.' }]
        );
      }

      setIsLoading(false);
    };

    if (shouldFetch) {
      fetchData();
    }
  }, [values, shouldFetch, productSlug]);

  return useMemo(
    () => ({ isLoading, dataPoints, conversionStatistics, errors }),
    [isLoading, dataPoints, conversionStatistics, errors]
  );
};
