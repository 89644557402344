import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { TrainerDetail } from 'pages';

import { formatDateTime } from 'utils';
import { notifyError, notifySuccess } from 'utils/notifications';

import { Admin, TableFooter, SortableHeader, YesNo, Rating } from 'components';

import { MAX_RATING } from './constants';
import { trainerRate } from './sdk';

const TrainerTable = ({ trainers, sort, orderBy, refetch, pagination }) => {
  const onRateHandler = useCallback(
    trainerId => async (e, { rating }) => {
      const { success } = await trainerRate(trainerId, rating);

      if (success) {
        notifySuccess('Rating updated successfully');
        refetch();
      } else {
        notifyError('Rating update failed');
      }
    },
    [refetch]
  );

  return (
    <Table celled selectable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="9">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <SortableHeader sort={sort} orderBy={orderBy} field="name">
            Name
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="company_name">
            Company Name
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="job_title">
            Job Title
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="is_published">
            Is published
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
            Created by
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
            Created at
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
            Updated by
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
            Updated at
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="rating">
            Rating
          </SortableHeader>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {trainers.map(trainer => (
          <Table.Row key={trainer.id}>
            <Table.Cell width={2}>
              <Link to={pageUrl(TrainerDetail, { trainerId: trainer.id })}>
                {trainer.name}
              </Link>
            </Table.Cell>
            <Table.Cell width={2}>{trainer.company_name}</Table.Cell>
            <Table.Cell width={2}>{trainer.job_title}</Table.Cell>
            <Table.Cell width={1}>
              <YesNo value={trainer.is_published} />
            </Table.Cell>
            <Table.Cell>
              <Admin value={trainer.created_by} />
            </Table.Cell>
            <Table.Cell>{formatDateTime(trainer.created_at)}</Table.Cell>
            <Table.Cell>
              <Admin value={trainer.updated_by} />
            </Table.Cell>
            <Table.Cell>{formatDateTime(trainer.updated_at)}</Table.Cell>
            <Table.Cell textAlign="center">
              <Rating
                rating={trainer.rating}
                maxRating={MAX_RATING}
                onRate={onRateHandler(trainer.id)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
};

export default TrainerTable;
