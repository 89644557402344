import _ from 'lodash';
import React, { useCallback } from 'react';
import { Divider, Header } from 'semantic-ui-react';

import { Button, DeleteModal, Flex } from 'components';

import { getAuthor, getCreatedDate } from './uitls';

export default function AdminCommentsArchive({
  commentArchive,
  clearArchive,
  sdkHandler
}) {
  const copyToClipboardHandler = useCallback(() => {
    const archiveAsString = commentArchive
      .map(item => {
        const date = getCreatedDate(item);
        const name = getAuthor(item);

        return `${date} ${name}:\n${item.comment}\n`;
      })
      .join('\n\n');

    navigator.clipboard.writeText(archiveAsString);
  }, [commentArchive]);

  if (_.isEmpty(commentArchive)) {
    return <Header as="h3">No admin comments archive yet.</Header>;
  }

  return (
    <Flex
      flexStart
      alignStart
      style={{
        flexDirection: 'column',
        marginBottom: '20px'
      }}>
      <Flex spaceBetween style={{ width: '100%' }}>
        <h4 style={{ margin: 0 }}>Admin Comments Archive</h4>

        <Flex>
          <Button color="green" onClick={copyToClipboardHandler}>
            Copy to Clipboard
          </Button>
          <DeleteModal
            objName="Admin Comments Archive"
            deleteButtonText="Clear"
            sdk={sdkHandler}
            onSuccess={clearArchive}
          />
        </Flex>
      </Flex>

      <Divider style={{ height: '100%', width: '100%' }} />

      <div
        style={{
          margin: '15px 5px',
          maxHeight: '40vh',
          overflow: 'auto',
          width: '100%'
        }}>
        {commentArchive.map((adminComment, index) => (
          <>
            <p style={{ whiteSpace: 'pre-wrap' }} key={index}>
              <span style={{ fontWeight: 'bold' }}>
                {getCreatedDate(adminComment)} {getAuthor(adminComment)}:
              </span>
              <p>{adminComment.comment}</p>
            </p>
            <br />
          </>
        ))}
      </div>
    </Flex>
  );
}
