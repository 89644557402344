export const STEEL_SLUG = 'steel';
export const POWER_MATERIAL_SLUG = 'power-materials';
export const HYDROGEN_SLUG = 'hydrogen';

export const GLOBAL_PRODUCT = {
  [HYDROGEN_SLUG]: {
    title: 'Hydrogen'
  },
  [POWER_MATERIAL_SLUG]: {
    title: 'Power Materials'
  },
  [STEEL_SLUG]: {
    title: 'Steel'
  }
};

// TODO: remove energy related constants when finally stop energy
export const KALLANISH_STEEL = 'Kallanish Steel';
export const KALLANISH_STEEL_LIVE = 'Kallanish Steel Live';
export const KALLANISH_STEEL_LITE = 'Kallanish Steel Lite';
export const KALLANISH_ENERGY = 'Kallanish Energy';
export const STEEL_INTELLIGENCE_CHINA = 'Steel Intelligence China';
export const STEEL_INTELLIGENCE_INDIA = 'Steel Intelligence India';
export const WEEKLY_STEEL_PREMIUM = 'Kallanish Steel Weekly Premium';
export const WEEKLY_STEEL_STANDARD = 'Kallanish Steel Weekly Standard';
export const WEEKLY_SEASM_PREMIUM = 'South East Asia Steel Markets Premium';
export const WEEKLY_SEASM_STANDARD = 'South East Asia Steel Markets Standard';
export const KALLANISH_STEEL_PULSE_MENA = 'Kallanish Steel Pulse MENA';
export const PRICES_DATA_FEED = 'Prices Data Feed';
export const NORTH_AMERICA_ENERGY = 'North America Energy';

export const KALLANISH_POWER_MATERIALS = 'Kallanish Power Materials';

export const HYDROGEN = 'Hydrogen';

export const LATEST_ISSUE_TITLES = [
  KALLANISH_ENERGY,
  KALLANISH_STEEL,
  STEEL_INTELLIGENCE_CHINA,
  STEEL_INTELLIGENCE_INDIA,
  WEEKLY_STEEL_PREMIUM,
  WEEKLY_STEEL_STANDARD,
  WEEKLY_SEASM_PREMIUM,
  WEEKLY_SEASM_STANDARD,
  KALLANISH_STEEL_PULSE_MENA,
  KALLANISH_STEEL_LIVE,
  KALLANISH_STEEL_LITE,
  NORTH_AMERICA_ENERGY,

  KALLANISH_POWER_MATERIALS
];

export const LATEST_ISSUE_DAILY_NEWSLETTERS = [
  KALLANISH_ENERGY,
  KALLANISH_STEEL,
  KALLANISH_STEEL_LIVE,
  KALLANISH_STEEL_LITE,
  NORTH_AMERICA_ENERGY,
  KALLANISH_POWER_MATERIALS
];

export const LATEST_ISSUE_WEEKLY_NEWSLETTERS = [
  WEEKLY_STEEL_STANDARD,
  WEEKLY_STEEL_PREMIUM
];

export const LATEST_ISSUE_WEEKLY_SEASM_NEWSLETTERS = [
  WEEKLY_SEASM_STANDARD,
  WEEKLY_SEASM_PREMIUM
];

export const LATEST_ISSUE_NEWSLETTER_ISSUES = {
  [KALLANISH_STEEL]: STEEL_SLUG,
  [KALLANISH_STEEL_LIVE]: STEEL_SLUG,
  [KALLANISH_STEEL_LITE]: STEEL_SLUG,
  [KALLANISH_POWER_MATERIALS]: POWER_MATERIAL_SLUG
};

export const SUBSCRIPTION = 'Subscription';
export const COURSE = 'Course';
export const REPORT = 'Report';
export const ADVERTISEMENT = 'Advertisement';

export const EDITION_WARNING_CONTENT_TYPES = {
  CLIENT: 'client',
  STEEL_ARTICLE: 'steel_article',
  POWER_MATERIALS_ARTICLE: 'power_materials_article',
  ENERGY_ARTICLE: 'energy_article',
  WEEKLY_STEEL: 'weekly_steel',
  WEEKLY_SEASM: 'weekly_seasm',
  WEEKLY_POWER_MATERIALS: 'weekly_power_materials',
  MAILSHOT: 'mailshot',
  COURSE: 'course',
  DAILY_STEEL_NEWSLETTER: 'daily_steel_newsletter',
  DAILY_POWER_MATERIALS_NEWSLETTER: 'daily_power_materials_newsletter',
  DAILY_ENERGY_NEWSLETTER: 'daily_energy_newsletter',
  DAILY_NA_ENERGY_NEWSLETTER: 'daily_na_energy_newsletter'
};
