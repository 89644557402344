import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklySteelDetail = async id => {
  const url = `${BASE_URL}/newsletters/weekly-steel/${id}/`;

  return await requestSdk(() => get(url));
};

export const weeklySteelUpdate = async (weeklySteelId, data) => {
  const url = `${BASE_URL}/newsletters/weekly-steel/update/${weeklySteelId}/`;

  return await requestSdk(() => post(url, data));
};

export const seasmEditionDetail = async id => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/${id}/`;

  return await requestSdk(() => get(url));
};

export const seasmEditionUpdate = async (weeklySteelId, data) => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/update/${weeklySteelId}/`;

  return await requestSdk(() => post(url, data));
};

export const steelPulseMenaEditionDetail = async id => {
  const url = `${BASE_URL}/reports/steel-pulse-mena/${id}/`;

  return await requestSdk(() => get(url));
};

export const steelPulseMenaEditionUpdate = async (steelPulseMenaId, data) => {
  const url = `${BASE_URL}/reports/steel-pulse-mena/update/${steelPulseMenaId}/`;

  return await requestSdk(() => post(url, data));
};

export const steelIntelligenceChinaEditionDetail = async id => {
  const url = `${BASE_URL}/reports/steel-intelligence/china/${id}/`;

  return await requestSdk(() => get(url));
};

export const steelIntelligenceChinaEditionUpdate = async (
  steelIntelligenceChinaId,
  data
) => {
  const url = `${BASE_URL}/reports/steel-intelligence/china/update/${steelIntelligenceChinaId}/`;

  return await requestSdk(() => post(url, data));
};

export const steelIntelligenceIndiaEditionDetail = async id => {
  const url = `${BASE_URL}/reports/steel-intelligence/india/${id}/`;

  return await requestSdk(() => get(url));
};

export const steelIntelligenceIndiaEditionUpdate = async (
  steelIntelligenceIndiaId,
  data
) => {
  const url = `${BASE_URL}/reports/steel-intelligence/india/update/${steelIntelligenceIndiaId}/`;

  return await requestSdk(() => post(url, data));
};
