import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const trainerUpdate = async (trainerId, data) => {
  const url = `${BASE_URL}/cms/trainers/${trainerId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const trainerArchiveAdminComments = async (trainerId, data) => {
  const url = `${BASE_URL}/cms/trainers/${trainerId}/admin-comments-archive/create/`;

  return await requestSdk(() => post(url, data));
};

export const trainerClearAdminCommentsArchive = async (trainerId, data) => {
  const url = `${BASE_URL}/cms/trainers/${trainerId}/admin-comments-archive/clear/`;

  return await requestSdk(() => post(url, data));
};
