import _ from 'lodash';
import React from 'react';

import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifyError, notifySuccess } from 'utils/notifications';
import {
  Field,
  UpdateButtonInRow,
  InputField,
  FormRow,
  CheckboxField,
  FileField,
  CourseTopicSelect,
  RichTextAreaField,
  VideoSelect,
  CourseVideoSelect,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues,
  UniSelect,
  TextAreaField,
  Button,
  Flex,
  AdminCommentsArchive
} from 'components';

import {
  trainerArchiveAdminComments,
  trainerClearAdminCommentsArchive,
  trainerUpdate
} from './sdk';

import { Events } from './components';

import { trainerUpdateSchema, getUpcomingEvents } from './utils';

import styles from './styles.module.css';

class TrainerUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { fetchTrainer } = this.props;
    const { trainerId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await trainerUpdate(trainerId, {
      ...prepareFormData(_.omit(values, 'linkedInUrl')),
      linkedIn_url: values.linkedInUrl
    });

    setSubmitting(false);

    if (success) {
      notifySuccess('Trainer has been updated successfully.');
      fetchTrainer();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  handleSaveToArchive = async admin_comments => {
    const { fetchTrainer } = this.props;
    const { trainerId } = this.props.match.params;

    const { success } = await trainerArchiveAdminComments(trainerId, {
      admin_comments
    });

    if (success) {
      notifySuccess('Admin comments have been saved to archive.');
      fetchTrainer();
      return;
    }

    notifyError('Failed to save admin comments to archive.');
  };

  render() {
    const { trainerId } = this.props.match.params;
    const { user, fetchTrainer } = this.props;
    const {
      name,
      slug,
      image,
      topics,
      email,
      twitter_url: twitterUrl,
      linkedIn_url: linkedInUrl,
      videos,
      video,
      biography,
      interview,
      client,
      company_name: companyName,
      job_title: jobTitle,
      is_published: isPublished,
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy,
      course_set: events,
      rating,
      admin_comments,
      admin_comments_archive
    } = this.props.data;

    const initialValues = {
      name,
      slug,
      image,
      topics,
      email,
      companyName,
      jobTitle,
      twitterUrl,
      linkedInUrl,
      videos: videos.map(v => v.id),
      video: video,
      biography,
      interview,
      isPublished,
      client,
      rating,
      admin_comments
    };

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={trainerUpdateSchema}>
        {({ handleSubmit, isSubmitting, dirty, values, setFieldValue }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow>
              <Field
                width={4}
                name="image"
                component={FileField}
                label="Image"
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="name"
                component={InputField}
                label="Name:"
              />
              <Field
                required
                width={6}
                name="slug"
                component={InputField}
                label="Slug:"
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="email"
                component={InputField}
                label="Email:"
              />
              <Field
                width={4}
                name="companyName"
                component={InputField}
                label="Company Name:"
              />
              <Field
                width={4}
                name="jobTitle"
                component={InputField}
                label="Job Title:"
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="twitterUrl"
                component={InputField}
                label="Twitter URL:"
              />
              <Field
                width={4}
                name="linkedInUrl"
                component={InputField}
                label="LinkedIn URL:"
              />
              <Field
                width={3}
                name="isPublished"
                component={CheckboxField}
                label="Is published"
              />
            </FormRow>
            {/* stop implementing events features */}
            {false && (
              <div className={styles.eventsGrid}>
                <Events
                  title="Upcoming Speaker Appointments"
                  events={getUpcomingEvents(events)}
                  fetchTrainer={this.props.fetchTrainer}
                  trainer={this.props.data}
                />
                <Events
                  style={{ margin: '0 0 1rem 0' }}
                  title="Upcoming Speaker Appointments"
                  fetchTrainer={this.props.fetchTrainer}
                  trainer={this.props.data}
                />
              </div>
            )}
            <FormRow>
              <Field
                width={6}
                name="topics"
                component={CourseTopicSelect}
                label="Topics:"
                isMulti={true}
              />
              <Field
                width={4}
                name="video"
                label="Video"
                component={CourseVideoSelect}
              />
              <UniSelect
                width={4}
                name="rating"
                label="Rating"
                source="speakerRatingOptions"
              />
            </FormRow>
            <FormRow>
              <Field
                width={10}
                name="videos"
                label="Videos:"
                component={VideoSelect}
              />
            </FormRow>
            <Field
              name="biography"
              label="Biography:"
              component={RichTextAreaField}
            />
            <Field
              name="interview"
              label="Interview:"
              component={RichTextAreaField}
            />

            <Field
              name="admin_comments"
              component={TextAreaField}
              label={
                <Flex flexStart>
                  <Flex style={{ gap: '20px' }}>
                    <h4 style={{ marginBottom: 0 }}>Admin comments</h4>
                    {user.is_superuser && (
                      <Button
                        disabled={
                          isSubmitting || _.isEmpty(values.admin_comments)
                        }
                        color="green"
                        onClick={async () => {
                          this.handleSaveToArchive(values.admin_comments);
                          setFieldValue('admin_comments', '');
                        }}>
                        Save To Archive
                      </Button>
                    )}
                  </Flex>
                </Flex>
              }
            />

            {user.is_superuser && (
              <AdminCommentsArchive
                commentArchive={admin_comments_archive}
                sdkHandler={() => trainerClearAdminCommentsArchive(trainerId)}
                clearArchive={() => fetchTrainer()}
              />
            )}

            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="Speaker"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(TrainerUpdateForm);
