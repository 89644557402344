import { transformValuesForMultiselect } from 'utils/filters';
import { EventSelect, InputField, UniSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'Search'
    },
    {
      name: 'events',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      component: EventSelect,
      label: 'Events',
      props: () => ({ isMulti: true })
    },
    {
      name: 'rating',
      emptyValue: null,
      component: UniSelect,
      label: 'Rating',
      props: () => ({ source: 'speakerRatingOptions' })
    }
  ]
];
