import { useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import { YesNo } from 'components';

const DEFAULT_VARIABLES = [
  ['{TITLE}', 'Client Title', true],
  ['{FIRST_NAME}', 'Client First Name', true],
  ['{LAST_NAME}', 'Client Last Name', true],
  ['{EMAIL}', 'Client E-mail', true],
  ['{{JOB_TITLE}}', 'Client Job Title', true],
  ['{COMPANY}', 'Client Company', true],
  ['{PASSWORD}', 'Client Password', true],
  ['{CURRENT_DATE}', 'Current date', true],
  ['{CURRENT_YEAR}', 'Current year', true],
  ['{ACCOUNT_SIGNATURE}', 'Account Manager Signature', false],
  ['{TERRITORY_ACCOUNT_SIGNATURE}', 'Sales Territory Manager Signature', false],
  ['{UNSUBSCRIBE_URL}', 'Unsubscribe Link', true],
  ['{WEB_VIEW_URL}', 'Web View Link', false]
];

const EmailVariables = ({ emailTemplate }) => {
  const data = useMemo(() => {
    if (
      emailTemplate &&
      emailTemplate.trigger === 'Partial Trial notification email'
    ) {
      // This email uses a different set of variables.
      return [
        ['{{FIRST_NAME}}', 'Client First Name', true],
        ['{{LAST_NAME}}', 'Client Last Name', true],
        ['{{EMAIL}}', 'Client E-mail', true],
        ['{{COMPANY}}', 'Client Company', true],
        ['{{JOB_TITLE}}', 'Client Job Title', true],
        [
          '{{PARTIAL_TRIAL_SERVICES}}',
          'Partial Trial Services from Free Trial, listed as bullets',
          false
        ],
        ['{CURRENT_DATE}', 'Current date', true],
        ['{CURRENT_YEAR}', 'Current year', true]
      ];
    } else if (emailTemplate && emailTemplate.trigger === 'Custom') {
      // We allow custom emails to use the subscription end date, because we have the Subscription there.
      return [
        ...DEFAULT_VARIABLES,
        ['{SUBSCRIPTION_END_DATE}', 'Client Subscription End Date', false]
      ];
    }

    return DEFAULT_VARIABLES;
  }, [emailTemplate]);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell width={1} style={{ whiteSpace: 'nowrap' }}>
            Use in Subject
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((item, index) => (
          <Table.Row key={index}>
            <Table.Cell>{item[0]}</Table.Cell>
            <Table.Cell>{item[1]}</Table.Cell>
            <Table.Cell>
              <YesNo value={item[2]}></YesNo>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default EmailVariables;
