import _ from 'lodash';

import { formatDateTime } from 'utils';

import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

import {
  MailshotDetail,
  WeeklySteelDetail,
  SEASMEditionDetail,
  DailyNewsletterDetail,
  NewsletterDetail,
  WeeklyPowerMaterialsDetail,
  SteelPulseMenaEditionDetail,
  SteelIntelligenceIndiaEditionDetail,
  SteelIntelligenceChinaEditionDetail
} from 'pages';

const calculatePercentageDone = target => {
  const percentage = (
    ((target.sent_email_count +
      target.failed_email_count +
      target.interrupted_email_count) /
      target.total_mail_count) *
    100
  ).toFixed(2);

  return percentage !== 'NaN' ? percentage : '-';
};

export const getTarget = target => {
  const {
    id,
    sending_status,
    send_when,
    sent_email_count,
    failed_email_count,
    interrupted_email_count,
    total_mail_count,
    target_type
  } = target;

  return {
    sendingStatus: sending_status,
    targetId: id,
    sendWhen: formatDateTime(send_when),
    sendEmailCount: sent_email_count,
    failedEmailCount: failed_email_count,
    interruptedEmailCount: interrupted_email_count,
    totalMailCount: total_mail_count,
    percentageDone: calculatePercentageDone(target),
    targetType: target_type
  };
};

export const prepareData = data => ({
  hasSendingObject: !_.isNil(
    _.find(data.targets, target => target.sending_status === 'Sending')
  ),
  targetType: data.target_type,
  targets: _.map(data.targets, target => getTarget(target))
});

export const TARGET_CUSTOM_COLORS = {
  Preparation: 'rgba(33, 133, 208, 0.25)',
  'Ready For Sending': 'rgba(251, 189, 8, 0.22)',
  Sending: 'rgba(33, 186, 69, 0.23)'
};

export const MAIL_TARGET_TYPES = {
  'daily-newsletter': 'Steel Newsletter',
  'power-material-newsletter': 'Power Materials Newsletter',
  // TODO remove this code when finally stop energy
  // 'energy-daily-newsletter': 'Energy Newsletter',
  // 'na-energy-daily-newsletter': 'NA Energy Newsletter',
  'weekly-steel': 'Weekly Steel',
  'weekly-seasm': 'Weekly Seasm',
  mailshot: 'Mailshot'
};

export const MAIL_TARGET_TYPES_URLS = {
  'daily-newsletter': {
    url: DailyNewsletterDetail,
    arg: 'dailyNewsletterId'
  },
  'weekly-steel': { url: WeeklySteelDetail, arg: 'id' },
  'weekly-seasm': { url: SEASMEditionDetail, arg: 'id' },
  'weekly-power-materials': { url: WeeklyPowerMaterialsDetail, arg: 'id' },
  'steel-pulse-mena': { url: SteelPulseMenaEditionDetail, arg: 'id' },
  'steel-intelligence-china': {
    url: SteelIntelligenceChinaEditionDetail,
    arg: 'id'
  },
  'steel-intelligence-india': {
    url: SteelIntelligenceIndiaEditionDetail,
    arg: 'id'
  },
  mailshot: { url: MailshotDetail, arg: 'mailshotId' },
  'daily-hydrogen': {
    url: NewsletterDetail,
    arg: 'newsletterId',
    additionalArgs: {
      productSlug: 'hydrogen'
    }
  },
  [`daily-${STEEL_SLUG}`]: {
    url: NewsletterDetail,
    arg: 'newsletterId',
    additionalArgs: {
      productSlug: STEEL_SLUG
    }
  },
  [`daily-${POWER_MATERIAL_SLUG}`]: {
    url: NewsletterDetail,
    arg: 'newsletterId',
    additionalArgs: {
      productSlug: POWER_MATERIAL_SLUG
    }
  }
};
