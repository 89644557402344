import _ from 'lodash';
import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  CheckboxField,
  FormRow,
  CreateButtonInRow,
  FileField,
  CourseTopicSelect,
  RichTextAreaField,
  VideoSelect,
  CourseVideoSelect,
  UnsavedFormPrompt,
  UniSelect,
  TextAreaField
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';

import { TrainerList } from 'pages';

import { trainerCreate } from './sdk';

import { trainerCreateSchema } from './utils';

class TrainerCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(_.omit(values, 'linkedInUrl')),
      linkedIn_url: values.linkedInUrl
    };

    setSubmitting(true);

    const { errors, success } = await trainerCreate(data);

    if (success) {
      notifySuccess(`Trainer added successfully`);
      this.props.history.push(pageUrl(TrainerList));
      return;
    }
    setSubmitting(false);
    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      name: '',
      slug: '',
      jobTitle: '',
      companyName: '',
      topics: [],
      videos: [],
      rating: null,
      admin_comments: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={trainerCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <FormRow>
              <Field
                width={4}
                name="image"
                component={FileField}
                label="Image"
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="name"
                component={InputField}
                label="Name"
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={6}
                name="slug"
                component={InputField}
                label="Slug"
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="email"
                component={InputField}
                label="Email:"
              />
              <Field
                width={4}
                name="companyName"
                component={InputField}
                label="Company Name:"
              />
              <Field
                width={4}
                name="jobTitle"
                component={InputField}
                label="Job Title:"
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="twitterUrl"
                component={InputField}
                label="Twitter URL:"
              />
              <Field
                width={4}
                name="linkedInUrl"
                component={InputField}
                label="LinkedIn URL:"
              />
              <Field
                width={3}
                name="isPublished"
                component={CheckboxField}
                label="Is published"
              />
            </FormRow>

            <FormRow>
              <Field
                width={6}
                name="topics"
                component={CourseTopicSelect}
                label="Topics"
                isMulti={true}
              />
              <Field
                width={4}
                name="video"
                label="Video"
                component={CourseVideoSelect}
              />
              <UniSelect
                width={4}
                name="rating"
                label="Rating"
                source="speakerRatingOptions"
              />
            </FormRow>
            <FormRow>
              <Field
                width={10}
                name="videos"
                label="Videos"
                component={VideoSelect}
              />
            </FormRow>
            <Field
              name="biography"
              label="Biography"
              component={RichTextAreaField}
            />
            <Field
              name="interview"
              label="Interview"
              component={RichTextAreaField}
            />
            <Field
              name="adminComments"
              component={TextAreaField}
              label="Admin comments"
            />
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(TrainerCreateForm);
