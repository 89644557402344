import { useCallback, useMemo, useState } from 'react';

import { buildFilters } from 'utils/filters';

import { priceSerieForUpdateList } from './sdk';
import { getPricesData } from './utils';
import { notifyErrors } from 'utils/notifications';

export const useFetchPriceSeriesForFrequency = ({
  frequency,
  filters,
  productSlug
}) => {
  const [loading, setLoading] = useState(false);
  const [priceSeriesHeaders, setPriceSeriesHeaders] = useState([]);
  const [priceSeriesDataRows, setPriceSeriesDataRows] = useState([]);

  const fetchPriceSeries = useCallback(async () => {
    setLoading(true);

    const { data, errors, success } = await priceSerieForUpdateList(
      frequency,
      buildFilters({ ...filters, product_type: productSlug })
    );

    if (success) {
      const { headers, pricesData } = getPricesData(data, frequency);

      setPriceSeriesHeaders(headers);
      setPriceSeriesDataRows(pricesData);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  }, [frequency, filters, productSlug]);

  return useMemo(
    () => ({
      loading,
      priceSeriesHeaders,
      priceSeriesDataRows,
      fetchPriceSeries
    }),
    [loading, priceSeriesHeaders, priceSeriesDataRows, fetchPriceSeries]
  );
};
