import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';

import {
  Segment,
  Table,
  Dimmer,
  Loader,
  Header,
  Button
} from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { Flex } from 'components';

import {
  MAIL_TARGET_TYPES_URLS,
  prepareData,
  TARGET_CUSTOM_COLORS
} from './utils';
import {
  STEEL_INTELLIGENCE_CHINA,
  STEEL_INTELLIGENCE_INDIA
} from 'domainConstants';

class StatTable extends React.Component {
  state = {
    data: {},
    isLoading: true
  };

  componentDidMount() {
    this.getStats();

    this.pollInterval = setInterval(this.getStats, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
    this.pollInterval = null;
  }

  getStats = async () => {
    const { success, data } = await this.props.getStats();

    if (success) {
      this.setState({ data: prepareData(data), isLoading: false });
    }
  };

  getMailTargetUrl = targetId => {
    const { url, arg, additionalArgs = {} } = MAIL_TARGET_TYPES_URLS[
      this.props.type
    ];

    return pageUrl(url, { [arg]: targetId, ...additionalArgs });
  };

  render() {
    const {
      data: { targetType, targets },
      isLoading
    } = this.state;

    const { receiversUrl } = this.props;

    return (
      <div>
        <Segment key={targetType}>
          {isLoading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
          ) : (
            <>
              <Flex spaceBetween>
                <Header as="h2">{targetType}</Header>
                {receiversUrl && (
                  <Button
                    onClick={() => this.props.history.push(receiversUrl())}>
                    {[
                      'Weekly Steel',
                      'Weekly Seasm',
                      'Weekly Power Materials',
                      'Steel Pulse MENA',
                      STEEL_INTELLIGENCE_INDIA,
                      STEEL_INTELLIGENCE_CHINA
                    ].includes(targetType)
                      ? 'View Opened'
                      : 'View Receivers'}
                  </Button>
                )}
              </Flex>
              <Table basic celled compact selectable structured>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                    <Table.HeaderCell>Date to go out</Table.HeaderCell>
                    <Table.HeaderCell>Sent</Table.HeaderCell>
                    <Table.HeaderCell>Failed</Table.HeaderCell>
                    <Table.HeaderCell>Interrupted</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell>% Done</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {_.isEmpty(targets) && (
                    <Table.Row>
                      <Table.Cell colSpan="8" textAlign="center">
                        No ready for sending objects found.
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {!_.isEmpty(targets) &&
                    targets.map((target, index) => {
                      return (
                        <Table.Row
                          key={index}
                          textAlign="center"
                          style={{
                            backgroundColor:
                              TARGET_CUSTOM_COLORS[target.sendingStatus]
                          }}>
                          <Table.Cell key={`target-id-${target.targetId}`}>
                            {targetType !== 'Instant News' ? (
                              <Link to={this.getMailTargetUrl(target.targetId)}>
                                {target.targetId}
                              </Link>
                            ) : (
                              target.targetId
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <strong>{target.sendingStatus}</strong>
                          </Table.Cell>
                          <Table.Cell>{target.sendWhen}</Table.Cell>
                          <Table.Cell>{target.sendEmailCount}</Table.Cell>
                          <Table.Cell>{target.failedEmailCount}</Table.Cell>
                          <Table.Cell>
                            {target.interruptedEmailCount}
                          </Table.Cell>
                          <Table.Cell>{target.totalMailCount}</Table.Cell>
                          <Table.Cell>{target.percentageDone}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </>
          )}
        </Segment>
      </div>
    );
  }
}

export default withRouter(StatTable);
