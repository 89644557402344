import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklySteelCreate = async data => {
  const url = `${BASE_URL}/newsletters/weekly-steel/create/`;

  return await requestSdk(() => post(url, data));
};

export const weeklySteelCreateTemplates = async () => {
  const url = `${BASE_URL}/newsletters/weekly-steel/create/templates/`;

  return await requestSdk(() => get(url));
};

export const seasmEditionCreate = async data => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/create/`;

  return await requestSdk(() => post(url, data));
};

export const seasmEditionTemplates = async () => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/create/templates/`;

  return await requestSdk(() => get(url));
};

export const steelPulseMenaEditionCreate = async data => {
  const url = `${BASE_URL}/reports/steel-pulse-mena/create/`;

  return await requestSdk(() => post(url, data));
};

export const steelPulseMenaEditionTemplates = async () => {
  const url = `${BASE_URL}/reports/steel-pulse-mena/create/templates/`;

  return await requestSdk(() => get(url));
};

export const steelIntelligenceChinaEditionCreate = async data => {
  const url = `${BASE_URL}/reports/steel-intelligence/china/create/`;

  return await requestSdk(() => post(url, data));
};

export const steelIntelligenceChinaEditionTemplates = async () => {
  const url = `${BASE_URL}/reports/steel-intelligence/china/create/templates/`;

  return await requestSdk(() => get(url));
};

export const steelIntelligenceIndiaEditionCreate = async data => {
  const url = `${BASE_URL}/reports/steel-intelligence/india/create/`;

  return await requestSdk(() => post(url, data));
};

export const steelIntelligenceIndiaEditionTemplates = async () => {
  const url = `${BASE_URL}/reports/steel-intelligence/india/create/templates/`;

  return await requestSdk(() => get(url));
};
