import React from 'react';

import { getSendLatestIssuePropsFromProduct } from './utils';

class LatestIssueModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  render() {
    const { client, product } = this.props;

    const {
      Component,
      weeklyUrlFragment,
      productSlug,
      newsletterTypeSlug,
      enableXlsAttachments
    } = getSendLatestIssuePropsFromProduct(product);

    return (
      <Component
        onClose={this.close}
        client={client}
        product={product}
        weeklyUrlFragment={weeklyUrlFragment}
        productSlug={productSlug}
        newsletterTypeSlug={newsletterTypeSlug}
        enableXlsAttachments={enableXlsAttachments}
      />
    );
  }
}

export default LatestIssueModal;
