import React from 'react';

import _ from 'lodash';

import { Segment, Header } from 'semantic-ui-react';

import { Page, LoginRequired, Flex, ContentEditionWarning } from 'components';
import {
  DisableableElement,
  hasPermission,
  PERMISSIONS,
  PermissionsRequired
} from 'permissions';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import {
  weeklySteelDetail,
  weeklySteelUpdate,
  seasmEditionDetail,
  seasmEditionUpdate,
  steelPulseMenaEditionDetail,
  steelPulseMenaEditionUpdate,
  steelIntelligenceIndiaEditionDetail,
  steelIntelligenceIndiaEditionUpdate,
  steelIntelligenceChinaEditionDetail,
  steelIntelligenceChinaEditionUpdate
} from './sdk';

import { WeeklySteelUpdateForm } from './components';

import withEditionDetails from '../withEditionDetails';
import SendingInstancesTable from './components/SendingInstancesTable';

class WeeklyEditionDetail extends React.Component {
  state = {
    weeklySteelData: null
  };

  componentDidMount() {
    this.fetchWeeklySteelDetail();
  }

  fetchWeeklySteelDetail = async () => {
    const { id } = this.props.match.params;
    const { detailSdk } = this.props.editionDetails;

    const { data, errors, success } = await detailSdk(id);

    if (success) {
      this.setState({ weeklySteelData: data });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { user, crumbs, editionDetails } = this.props;
    const {
      changePermissions,
      title,
      updateSdk,
      detailSdk,
      enableXlsAttachments
    } = editionDetails;

    const { weeklySteelData } = this.state;
    const sendingStatus = _.get(weeklySteelData, 'sending_status', '');

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {!_.isNil(weeklySteelData) && (
              <ContentEditionWarning
                user={user}
                contentType={EDITION_WARNING_CONTENT_TYPES.WEEKLY_STEEL}
                contentId={weeklySteelData.id}
              />
            )}

            <Segment>
              <Flex spaceBetween>
                <Header>Edit {title}</Header>
                <Segment>
                  <p>
                    Sending status: <strong>{sendingStatus}</strong>
                  </p>
                </Segment>
              </Flex>
              {!_.isNil(weeklySteelData) && (
                <DisableableElement
                  disabled={!hasPermission(user, changePermissions)}>
                  <WeeklySteelUpdateForm
                    data={weeklySteelData}
                    fetchWeeklySteelDetail={this.fetchWeeklySteelDetail}
                    subject={title}
                    updateSdk={updateSdk}
                    enableXlsAttachments={enableXlsAttachments}
                  />
                </DisableableElement>
              )}
            </Segment>
            {!_.isNil(weeklySteelData) &&
              _.has(weeklySteelData, 'sending_instances') && (
                <Segment>
                  <Header>Sending instances</Header>
                  <br />
                  <SendingInstancesTable
                    instance={weeklySteelData}
                    detailSdk={detailSdk}
                    refetch={this.fetchWeeklySteelDetail}
                  />
                  <br />
                </Segment>
              )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

const WeeklySteelDetail = LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_WEEKLY_STEEL])(
    withEditionDetails({
      editionDetails: {
        detailSdk: weeklySteelDetail,
        updateSdk: weeklySteelUpdate,
        changePermissions: PERMISSIONS.CHANGE_WEEKLY_STEEL,
        title: 'weekly steel'
      }
    })(WeeklyEditionDetail)
  )
);

const SEASMEditionDetail = LoginRequired(
  // TODO: change permission
  PermissionsRequired([PERMISSIONS.VIEW_SEASM_REPORT])(
    withEditionDetails({
      editionDetails: {
        detailSdk: seasmEditionDetail,
        updateSdk: seasmEditionUpdate,
        // TODO: change permission
        changePermissions: PERMISSIONS.CHANGE_SEASM_REPORT,
        title: 'SEASM report'
      }
    })(WeeklyEditionDetail)
  )
);

const SteelPulseMenaEditionDetail = LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_STEEL_PULSE_MENA_REPORT])(
    withEditionDetails({
      editionDetails: {
        detailSdk: steelPulseMenaEditionDetail,
        updateSdk: steelPulseMenaEditionUpdate,
        changePermissions: PERMISSIONS.CHANGE_STEEL_PULSE_MENA_REPORT,
        title: 'Steel Pulse MENA report'
      }
    })(WeeklyEditionDetail)
  )
);

const SteelIntelligenceChinaEditionDetail = LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_STEEL_INTELLIGENCE_CHINA_REPORT])(
    withEditionDetails({
      editionDetails: {
        detailSdk: steelIntelligenceChinaEditionDetail,
        updateSdk: steelIntelligenceChinaEditionUpdate,
        changePermissions: PERMISSIONS.CHANGE_STEEL_INTELLIGENCE_CHINA_REPORT,
        title: 'Steel Intelligence China report',
        enableXlsAttachments: true
      }
    })(WeeklyEditionDetail)
  )
);

const SteelIntelligenceIndiaEditionDetail = LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_STEEL_INTELLIGENCE_INDIA_REPORT])(
    withEditionDetails({
      editionDetails: {
        detailSdk: steelIntelligenceIndiaEditionDetail,
        updateSdk: steelIntelligenceIndiaEditionUpdate,
        changePermissions: PERMISSIONS.CHANGE_STEEL_INTELLIGENCE_INDIA_REPORT,
        title: 'Steel Intelligence India report'
      }
    })(WeeklyEditionDetail)
  )
);

export {
  WeeklySteelDetail,
  SEASMEditionDetail,
  SteelPulseMenaEditionDetail,
  SteelIntelligenceChinaEditionDetail,
  SteelIntelligenceIndiaEditionDetail
};
