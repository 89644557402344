import { Rating as SemanticUIRating } from 'semantic-ui-react';

export default function Rating({
  rating,
  maxRating,
  disabled = false,
  onRate = undefined
}) {
  return (
    <SemanticUIRating
      clearable
      icon="star"
      disabled={disabled}
      rating={rating}
      maxRating={maxRating}
      onRate={onRate}
    />
  );
}
