import {
  LATEST_ISSUE_DAILY_NEWSLETTERS,
  LATEST_ISSUE_WEEKLY_NEWSLETTERS,
  LATEST_ISSUE_WEEKLY_SEASM_NEWSLETTERS,
  STEEL_INTELLIGENCE_CHINA,
  LATEST_ISSUE_NEWSLETTER_ISSUES,
  KALLANISH_STEEL_PULSE_MENA,
  STEEL_INTELLIGENCE_INDIA
} from 'domainConstants';

import {
  DailyNewsletters,
  WeeklyNewsletters,
  NewsletterIssue
} from './components';

export const getSendLatestIssuePropsFromProduct = product => {
  let Component = null;
  let weeklyUrlFragment = null;
  let enableXlsAttachments = false;
  let productSlug = null;
  let newsletterTypeSlug = null;

  if (LATEST_ISSUE_DAILY_NEWSLETTERS.includes(product.description)) {
    Component = DailyNewsletters;
  } else if (LATEST_ISSUE_WEEKLY_NEWSLETTERS.includes(product.description)) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'weekly';
  } else if (
    LATEST_ISSUE_WEEKLY_SEASM_NEWSLETTERS.includes(product.description)
  ) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'weekly-seasm';
  } else if (product.description === STEEL_INTELLIGENCE_CHINA) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'steel-intelligence-china';
    enableXlsAttachments = true;
  } else if (product.description === KALLANISH_STEEL_PULSE_MENA) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'steel-pulse-mena';
  } else if (product.description === STEEL_INTELLIGENCE_CHINA) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'steel-intelligence-china';
  } else if (product.description === STEEL_INTELLIGENCE_INDIA) {
    Component = WeeklyNewsletters;
    weeklyUrlFragment = 'steel-intelligence-india';
  }

  if (
    Object.keys(LATEST_ISSUE_NEWSLETTER_ISSUES).includes(product.description)
  ) {
    Component = NewsletterIssue;
    productSlug = LATEST_ISSUE_NEWSLETTER_ISSUES[product.description];
    newsletterTypeSlug = 'daily';
  }

  return {
    Component,
    productSlug,
    newsletterTypeSlug,
    weeklyUrlFragment,
    enableXlsAttachments
  };
};
