import React from 'react';

import _ from 'lodash';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { weeklyEditionOpenCount, reportEditionOpenCount } from './sdk';

import { WeeklySteelOpenCountTable, withEditionDetails } from './components';

import { FILTERS } from './utils';

class WeeklyEditionOpenCount extends React.Component {
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort,
      crumbs,
      isFetching,
      editionDetails
    } = this.props;

    const { title } = editionDetails;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Header>{title}</Header>
            <SharedFilters
              filters={filters}
              clearFilters={clear}
              filterBy={filterBy}
              count={count}
              layout={FILTERS}
            />
            <WeeklySteelOpenCountTable
              items={data}
              orderBy={orderBy}
              sort={sort}
              isFetching={isFetching}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

const WeeklySteelOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'weekly-steel';
    return _.partial(weeklyEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Weekly Steel Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

const WeeklySeasmOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'weekly-seasm';
    return _.partial(weeklyEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Weekly SEASM Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

const WeeklyPowerMaterialsOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'weekly-power-materials';
    return _.partial(weeklyEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Weekly Power Materials Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

const SteelPulseMenaOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'steel-pulse-mena';
    return _.partial(reportEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Steel Pulse MENA Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

const SteelIntelligenceChinaOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'steel-intelligence/china';
    return _.partial(reportEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Steel Intelligence China Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

const SteelIntelligenceIndiaOpenCount = ListViewEssentials({
  sdk: props => {
    const editionType = 'steel-intelligence/india';
    return _.partial(reportEditionOpenCount, editionType);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withEditionDetails({
      editionDetails: {
        title: 'Steel Intelligence India Open Count'
      }
    })(WeeklyEditionOpenCount)
  )
);

export {
  WeeklySeasmOpenCount,
  WeeklySteelOpenCount,
  WeeklyPowerMaterialsOpenCount,
  SteelPulseMenaOpenCount,
  SteelIntelligenceChinaOpenCount,
  SteelIntelligenceIndiaOpenCount
};
