import { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import { Header, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Page, EmailVariables, LoginRequired } from 'components';
import {
  PERMISSIONS,
  PermissionsRequired,
  DisableableElement,
  hasPermission
} from 'permissions';

import { EmailTemplateUpdateForm } from './components';

import { emailTemplateDetail } from './sdk';
import { notifyErrors } from 'utils/notifications';

const EmailTemplateDetail = ({ user, crumbs, match }) => {
  const [emailTemplate, setEmailTemplate] = useState(null);

  const fetchEmailTemplate = useCallback(async () => {
    const { emailTemplateId } = match.params;

    const { data, errors, success } = await emailTemplateDetail(
      emailTemplateId
    );

    if (success) {
      setEmailTemplate(data);
    } else {
      notifyErrors(errors);
    }
  }, [match.params]);

  useEffect(() => {
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Change Email Template</Header>
            {!_.isNil(emailTemplate) && (
              <DisableableElement
                disabled={!hasPermission(user, PERMISSIONS.CHANGE_MAILSHOTS)}>
                <EmailTemplateUpdateForm
                  data={emailTemplate}
                  fetchEmailTemplate={fetchEmailTemplate}
                />
              </DisableableElement>
            )}
          </Segment>
          <Segment>
            <EmailVariables emailTemplate={emailTemplate} />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
    withRouter(EmailTemplateDetail)
  )
);
