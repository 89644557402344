import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import withEditionDetails from '../withEditionDetails';

import { WeeklySteelCreateForm } from './components';
import {
  weeklySteelCreate,
  weeklySteelCreateTemplates,
  seasmEditionCreate,
  seasmEditionTemplates,
  steelPulseMenaEditionCreate,
  steelPulseMenaEditionTemplates,
  steelIntelligenceIndiaEditionCreate,
  steelIntelligenceIndiaEditionTemplates,
  steelIntelligenceChinaEditionCreate,
  steelIntelligenceChinaEditionTemplates
} from './sdk';

class WeeklyEditionCreate extends React.Component {
  render() {
    const { user, crumbs, editionDetails } = this.props;

    const {
      title,
      editionCreateSdk,
      editionTemplatesSdk,
      successPage,
      enableXlsAttachments
    } = editionDetails;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create {title}</Header>
              <WeeklySteelCreateForm
                editionTemplatesSdk={editionTemplatesSdk}
                editionCreateSdk={editionCreateSdk}
                successPage={successPage}
                title={title}
                enableXlsAttachments={enableXlsAttachments}
              />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

const WeeklySteelCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_WEEKLY_STEEL])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: weeklySteelCreate,
        editionTemplatesSdk: weeklySteelCreateTemplates,
        title: 'Weekly Steel'
      }
    })(WeeklyEditionCreate)
  )
);

const SEASMEdtionCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_SEASM_REPORT])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: seasmEditionCreate,
        editionTemplatesSdk: seasmEditionTemplates,
        title: 'SEASM report'
      }
    })(WeeklyEditionCreate)
  )
);

const SteelPulseMenaEditionCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_STEEL_PULSE_MENA_REPORT])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: steelPulseMenaEditionCreate,
        editionTemplatesSdk: steelPulseMenaEditionTemplates,
        title: 'Steel Pulse MENA report'
      }
    })(WeeklyEditionCreate)
  )
);

const SteelIntelligenceChinaEditionCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_STEEL_INTELLIGENCE_CHINA_REPORT])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: steelIntelligenceChinaEditionCreate,
        editionTemplatesSdk: steelIntelligenceChinaEditionTemplates,
        title: 'Steel Intelligence China report',
        enableXlsAttachments: true
      }
    })(WeeklyEditionCreate)
  )
);

const SteelIntelligenceIndiaEditionCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_STEEL_INTELLIGENCE_INDIA_REPORT])(
    withEditionDetails({
      editionDetails: {
        editionCreateSdk: steelIntelligenceIndiaEditionCreate,
        editionTemplatesSdk: steelIntelligenceIndiaEditionTemplates,
        title: 'Steel Intelligence India report'
      }
    })(WeeklyEditionCreate)
  )
);

export {
  WeeklySteelCreate,
  SEASMEdtionCreate,
  SteelPulseMenaEditionCreate,
  SteelIntelligenceChinaEditionCreate,
  SteelIntelligenceIndiaEditionCreate
};
