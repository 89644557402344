import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklySteelList = async params => {
  const url = `${BASE_URL}/newsletters/weekly-steel/`;

  return await requestSdk(() => get(url, params));
};

export const seasmEditionList = async params => {
  // TODO: change url
  const url = `${BASE_URL}/newsletters/weekly-seasm/`;

  return await requestSdk(() => get(url, params));
};

export const steelPulseMenaEditionList = async params => {
  const url = `${BASE_URL}/reports/steel-pulse-mena/`;

  return await requestSdk(() => get(url, params));
};

export const steelIntelligenceChinaEditionList = async params => {
  const url = `${BASE_URL}/reports/steel-intelligence/china/`;

  return await requestSdk(() => get(url, params));
};

export const steelIntelligenceIndiaEditionList = async params => {
  const url = `${BASE_URL}/reports/steel-intelligence/india/`;

  return await requestSdk(() => get(url, params));
};
