import * as yup from 'yup';

export const weeklySteelCreateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  issueNumber: yup.string().required('Issue number is required.'),
  lead: yup.string().required('Lead is required.'),
  text: yup.string().required('Text is required.'),
  datetime: yup
    .string()
    .nullable()
    .required('Date to go out is required.'),
  issueDate: yup
    .string()
    .nullable()
    .required('Issue date is required.'),
  editor: yup
    .string()
    .nullable()
    .required('Editor is required.'),
  marketer: yup
    .string()
    .nullable()
    .required('Marketer is required.'),
  pdf: yup
    .string()
    .nullable()
    .required('PDF is required.'),
  cover: yup
    .string()
    .nullable()
    .required('Cover image is required.'),
  samplePdf: yup
    .string()
    .nullable()
    .required('Sample PDF image is required.')
});

export const AdditionalValidationCreateSchema = yup.object().shape({
  xls: yup
    .string()
    .nullable()
    .required('XLS is required.'),
  sampleXls: yup
    .string()
    .nullable()
    .required('Sample XLS is required.')
});
