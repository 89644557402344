import React, { useEffect } from 'react';

import _ from 'lodash';

import {
  Segment,
  Header,
  Table,
  Dimmer,
  Loader,
  Button
} from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

import { Flex } from 'components';

import { PriceSerieDetail } from 'pages';
import { pageUrl } from 'config/routes';
import { hasPermission, PERMISSIONS } from 'permissions';

import { CombinedPriceInfoTooltip, PriceSeriesData } from './components';

function PriceSeriesFrequencyTable({
  frequency,
  user,
  filters,
  filterBy,
  disabled,
  steps,
  loading,
  priceSeriesHeaders,
  priceSeriesDataRows,
  fetchPriceSeries,
  refetchOtherPrices
}) {
  const { productSlug } = useParams();

  useEffect(() => {
    fetchPriceSeries();
  }, [fetchPriceSeries]);

  const canUserUpdate =
    !disabled && hasPermission(user, PERMISSIONS.CHANGE_PRICES);

  const passFilters = f => {
    const _filterBy = dateFilters => filterBy({ ...filters, ...dateFilters });

    return () => f(filters, _filterBy);
  };

  const {
    onSmallStepBack,
    onBigStepBack,
    onSmallStepForward,
    onBigStepForward
  } = steps || {};

  if (_.isEmpty(priceSeriesDataRows)) {
    return null;
  }

  return (
    <Segment>
      <Flex spaceBetween>
        <Header>{_.capitalize(frequency)} Price Series</Header>

        <div>
          <Button
            icon="angle double left"
            onClick={passFilters(onBigStepBack)}
          />
          <Button icon="angle left" onClick={passFilters(onSmallStepBack)} />

          <Button
            icon="angle right"
            onClick={passFilters(onSmallStepForward)}
          />
          <Button
            icon="angle double right"
            onClick={passFilters(onBigStepForward)}
          />
        </div>
      </Flex>

      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}

      <Table celled compact striped selectable definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {_.capitalize(frequency)} Price Series
            </Table.HeaderCell>
            {priceSeriesHeaders.map(header => (
              <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {priceSeriesDataRows.map((row, index) => (
            <Table.Row key={index}>
              <Table.Cell width={4}>
                {canUserUpdate ? (
                  <a
                    href={pageUrl(PriceSerieDetail, {
                      productSlug,
                      priceSeriesId: row[0].id
                    })}
                    target="_blank"
                    rel="noreferrer">
                    {row[0].title}
                  </a>
                ) : (
                  row[0].title
                )}{' '}
                {row[0].isCombined && <CombinedPriceInfoTooltip />}
              </Table.Cell>
              {row.slice(1).map((item, index) => (
                <PriceSeriesData
                  key={`${item.date}:${item.id}`}
                  priceSeries={item}
                  disabled={!canUserUpdate}
                  fetchForFrequency={fetchPriceSeries}
                  user={user}
                  frequency={frequency}
                  isCombined={row[0].isCombined}
                  refetchOtherPrices={refetchOtherPrices}
                />
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default PriceSeriesFrequencyTable;
