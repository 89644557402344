import _ from 'lodash';
import React from 'react';

import { Field, TransferListField, AsyncSelectField } from 'components';
import { Choices } from './sdk';
import { FastField } from 'formik';
import { SelectField } from 'components/fields';

/**
 * Example usage:
 * https://github.com/kallanish/kallanish-frontend/wiki/UniSelect
 * https://whimsical.com/uniselect-H93hKapYiJgzmmCe6iNaqg
 */
class UniSelect extends React.Component {
  state = {
    choices: []
  };

  async setChoices(props) {
    const choices = await Choices.get(props);
    this.setState({ choices: choices });
    return choices;
  }

  async componentDidMount() {
    const { autoComplete } = this.props;
    if (!autoComplete) {
      this.setChoices(this.props);
    }
  }

  loadOptions = (inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }
    const params = { params: { ...this.props.params, search: inputValue } };
    return this.setChoices({ ...this.props, ...params });
  };

  render() {
    const {
      name,
      isCustom,
      keyField,
      valueField,
      labelField,
      fast,
      transferList,
      autoComplete,
      source,
      components = {},
      excludeOptionsIds = null,
      ...rest
    } = this.props;
    const { choices } = this.state;

    let choiceOptions = choices;

    if (excludeOptionsIds) {
      choiceOptions = _.filter(
        choiceOptions,
        option => _.indexOf(excludeOptionsIds, option.value) < 0
      );
    }

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    let internalComponent = SelectField;
    if (transferList) {
      internalComponent = TransferListField;
    }
    if (autoComplete) {
      internalComponent = AsyncSelectField;
    }

    return (
      <Component
        key={`${name}-${source}-select`}
        name={name}
        component={internalComponent}
        options={choiceOptions}
        loadOptions={this.loadOptions}
        isClearable
        components={components}
        {...rest}
      />
    );
  }
}

export default UniSelect;
