import React from 'react';

import { Link } from 'react-router-dom';
import { Label, Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { formatDateTime } from 'utils';

import { YesNo, Admin, TableFooter, SortableHeader } from 'components';

const WeeklySteelTable = ({ items, sort, orderBy, pagination, detailPage }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="11">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date">
          Date to go out
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="sending_status">
          Sending Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor_approved">
          Editor Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor">
          Editor
        </SortableHeader>
        <SortableHeader
          sort={sort}
          orderBy={orderBy}
          field="marketing_approved">
          Marketing Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="marketer">
          Marketer
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="copies">
          Copies sent
        </SortableHeader>
        <Table.HeaderCell>Report will be sent to</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {items.map(item => (
        <Table.Row key={item.id}>
          <Table.Cell width={4} data-testid="weekly-steel-title">
            {item.migrated && (
              <Label color="green" ribbon size="small">
                Migrated from CSI
              </Label>
            )}
            <div>
              <Link to={pageUrl(detailPage, { id: item.id })}>
                {item.title}
              </Link>
            </div>
          </Table.Cell>
          <Table.Cell width={2} data-testid="weekly-steel-date">
            {formatDateTime(item.datetime)}
          </Table.Cell>
          <Table.Cell data-testid="weekly-steel-status">
            {item.status}
          </Table.Cell>
          <Table.Cell width={1} data-testid="weekly-steel-sending-status">
            {item.sending_status}
          </Table.Cell>
          <Table.Cell data-testid="weekly-steel-editor-approved">
            <YesNo value={item.editor_approved} />
          </Table.Cell>
          <Table.Cell width={2} data-testid="weekly-steel-editor">
            <Admin value={item.editor} />
          </Table.Cell>
          <Table.Cell data-testid="weekly-steel-marketing-approved">
            <YesNo value={item.marketing_approved} />
          </Table.Cell>
          <Table.Cell width={2} data-testid="weekly-steel-marketer">
            <Admin value={item.marketer} />
          </Table.Cell>
          <Table.Cell width={2} data-testid="weekly-steel-created-by">
            <Admin value={item.created_by} />
          </Table.Cell>
          <Table.Cell width={1} data-testid="weekly-steel-sent-count">
            {item.sent_count}
          </Table.Cell>
          <Table.Cell data-testid="weekly-steel-receivers-count">
            {item.receivers_count}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default WeeklySteelTable;
