import React, { useRef, useState } from 'react';

import cx from 'classnames';

import _ from 'lodash';

import { Table, Input, Button, Icon, Popup } from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

import { HYDROGEN_SLUG } from 'domainConstants';
import { Flex, LinkButton, DeleteModal } from 'components';

import { DisableableElement } from 'permissions';

import { notifyErrors, notifySuccess } from 'utils/notifications';

import { VisibleIf, PERMISSIONS } from 'permissions';

import {
  isLowGreaterThanHigh,
  compare,
  getPriceValues,
  hasWarnings,
  calculateDaysDifference,
  shouldShowBigTimeGapAlert
} from './utils';

import {
  priceSeriesDataCreate,
  priceSeriesDataCommentCreate,
  priceSeriesDataDelete
} from './sdk';

import styles from './styles.module.css';

const SINGLE_PRICE = 'Single Price';
const RANGE = 'Range';

const PriceSeriesDataComment = ({
  open,
  onOpen,
  onClose,
  priceSeries,
  fetchForFrequency
}) => {
  const data = _.get(priceSeries, 'data');

  const isEdit = data && data.comment && data.comment !== '';

  const label = isEdit ? 'Edit comment' : 'Add comment';
  const comment = data && data.comment ? data.comment : '';

  const textAreaInput = useRef(null);

  const submitComment = async () => {
    const postData = {
      date: priceSeries.date,
      comment: textAreaInput.current.value
    };

    const { success, errors } = await priceSeriesDataCommentCreate(
      priceSeries.id,
      postData
    );

    if (success) {
      notifySuccess('Price comment added successfully.');
      fetchForFrequency();
    }

    if (!success) {
      notifyErrors(errors);
    }
    onClose();
  };

  return (
    <Popup
      on="click"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      content={
        <div className={styles.Cell}>
          <textarea
            style={{ minHeight: 100, maxHeight: 300 }}
            defaultValue={comment}
            ref={textAreaInput}
          />
          <Button onClick={submitComment}>Save</Button>
        </div>
      }
      trigger={
        <LinkButton>
          {isEdit ? (
            <strong className={styles.EditComment}>{label}</strong>
          ) : (
            label
          )}
        </LinkButton>
      }
    />
  );
};

const PriceSeriesDataDelete = ({
  open,
  onOpen,
  onClose,
  priceSeries,
  fetchForFrequency,
  disabled = false
}) => {
  const data = _.get(priceSeries, 'data');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  let dataPointStr = ``;
  if (data) {
    dataPointStr = `${data.value_1}`;
    if (data.value_1 && data.value_2) {
      dataPointStr = `${data.value_1} / ${data.value_2}`;
    }
  }

  if (disabled) {
    return <span className={styles.DeleteLinkDisabled}>Delete</span>;
  }

  return (
    <span>
      {data && (
        <DeleteModal
          key={`delete-modal-${data.id}`}
          displayButton={false}
          itemId={data.id}
          open={openDeleteModal}
          objName="Data point"
          objDetails={`value: ${dataPointStr} with ID: ${data.id} from date: ${priceSeries.date}`}
          sdk={priceSeriesDataDelete}
          onClose={() => setOpenDeleteModal(false)}
          onSuccess={fetchForFrequency}
        />
      )}
      {data && (
        <LinkButton disabled>
          <span
            className={styles.DeleteLink}
            onClick={() => setOpenDeleteModal(true)}>
            Delete
          </span>
        </LinkButton>
      )}
    </span>
  );
};

function PriceSeriesData({
  disabled,
  priceSeries,
  fetchForFrequency,
  user,
  frequency,
  refetchOtherPrices,
  isCombined = false
}) {
  const { productSlug } = useParams();

  const { value1, value2, mid_value } = getPriceValues(priceSeries);

  const [editMode, setEditMode] = useState(false);
  const [commentMode, setCommentMode] = useState(false);
  const [newValue1, setNewValue1] = useState(value1);
  const [newValue2, setNewValue2] = useState(value2);

  const isHydrogen = productSlug === HYDROGEN_SLUG;

  const onInputChange = (value, event, setValue) => {
    const inputValue = event.target.value;

    const hasChange = !compare(value, inputValue);

    if (hasChange && !editMode) {
      setEditMode(true);
    } else if (!hasChange && editMode) {
      setEditMode(false);
    }

    setValue(inputValue);
  };

  const hasWarning = hasWarnings(newValue1, newValue2, priceSeries);

  const hasError = isLowGreaterThanHigh(newValue1, newValue2);

  const daysDifference = calculateDaysDifference(priceSeries.date);
  const hasBigTimeGap = shouldShowBigTimeGapAlert(daysDifference, frequency);

  const onPriceSave = async () => {
    if (hasError) {
      let result = window.confirm('Low is greater than high. Are you sure?');
      if (!result) {
        return;
      }
    }

    if (hasWarning) {
      let result = window.confirm('The price is above 10% diff. Are you sure?');
      if (!result) {
        return;
      }
    }

    if (hasBigTimeGap) {
      let result = window.confirm(
        `This data point is ${daysDifference} days in the future. Are you sure you want to create it?`
      );
      if (!result) {
        return;
      }
    }

    const postData = {
      value_1: newValue1,
      value_2: newValue2,
      value_date: priceSeries.date
    };

    const { success, errors } = await priceSeriesDataCreate(
      priceSeries.id,
      postData
    );

    if (success) {
      notifySuccess('Price updated successfully.');
      setEditMode(false);
      setCommentMode(false);
      fetchForFrequency();

      if (priceSeries.isPartOfCombinations) {
        refetchOtherPrices();
      }
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  return (
    <Table.Cell
      className={cx({
        [styles.Warning]: hasWarning,
        [styles.Error]: hasError
      })}>
      <DisableableElement disabled={disabled}>
        <div className={styles.Cell}>
          {priceSeries.format === SINGLE_PRICE && (
            <Input
              key={`single-${value1}`}
              className={styles.Input}
              defaultValue={value1}
              onChange={event => onInputChange(value1, event, setNewValue1)}
              disabled={isCombined}
            />
          )}
          {priceSeries.format === RANGE && (
            <div className={styles.Range}>
              <Flex>Low</Flex>
              <Input
                key={`range-value1-${value1}`}
                className={styles.Input}
                defaultValue={value1}
                onChange={event => onInputChange(value1, event, setNewValue1)}
                disabled={isCombined}
              />
              {isHydrogen && (
                <>
                  <Flex>Mid</Flex>
                  <Input
                    key={`range-mid-value-${mid_value}`}
                    className={styles.Input}
                    defaultValue={mid_value}
                  />
                </>
              )}
              <Flex>High</Flex>
              <Input
                key={`range-value2-${value2}`}
                className={styles.Input}
                defaultValue={value2}
                onChange={event => onInputChange(value2, event, setNewValue2)}
                disabled={isCombined}
              />
            </div>
          )}
          {editMode && (
            <Button
              icon
              className={styles.SaveButton}
              color="green"
              onClick={onPriceSave}>
              <Icon name="check" />
            </Button>
          )}
        </div>
        <PriceSeriesDataComment
          open={commentMode}
          onOpen={() => setCommentMode(true)}
          onClose={() => setCommentMode(false)}
          priceSeries={priceSeries}
          fetchForFrequency={fetchForFrequency}
        />
        <VisibleIf user={user} permissions={[PERMISSIONS.DELETE_PRICES]}>
          <PriceSeriesDataDelete
            open={commentMode}
            priceSeries={priceSeries}
            fetchForFrequency={() => {
              fetchForFrequency();

              if (priceSeries.isPartOfCombinations) {
                refetchOtherPrices();
              }
            }}
            disabled={isCombined}
          />
        </VisibleIf>
      </DisableableElement>
    </Table.Cell>
  );
}

export default PriceSeriesData;
