import { Fragment } from 'react';
import { components } from 'react-select';
import { Label } from 'semantic-ui-react';

import { getOperationSign } from 'entities/PriceSerie';
import { UniSelect } from 'components';

function CombinedPriceSeriesItem({ label, extraData }) {
  return (
    <div style={{ display: 'flex', gap: '2px', flexWrap: 'wrap' }}>
      <Label horizontal color="teal">
        {label}
      </Label>

      {extraData.map((componentPrice, index) => (
        <Fragment key={componentPrice.id}>
          <Label horizontal circular>
            {index === 0 ? '=' : getOperationSign(componentPrice.operation)}
          </Label>
          <Label horizontal color="green">
            {componentPrice.display_as}
          </Label>
        </Fragment>
      ))}
    </div>
  );
}

export function Option(props) {
  const { data } = props;

  return (
    <components.Option {...props}>
      <CombinedPriceSeriesItem label={data.label} extraData={data.extraData} />
    </components.Option>
  );
}

export function SingleValue(props) {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <CombinedPriceSeriesItem label={data.label} extraData={data.extraData} />
    </components.SingleValue>
  );
}

export default function CombinedPriceSeriesSelect(props) {
  return (
    <UniSelect
      {...props}
      components={{
        Option: Option,
        SingleValue: SingleValue
      }}
    />
  );
}
