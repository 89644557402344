import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const trainerRate = async (trainerId, rating) => {
  const url = `${BASE_URL}/cms/trainers/${trainerId}/rate/`;

  return await requestSdk(() =>
    post(url, { rating: rating > 0 ? rating : null })
  );
};
