import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Page, Header, Filters as SharedFilters } from 'components';
import { PERMISSIONS } from 'permissions';

import { withPermissions, withLoggedUser, useFilters } from 'hooks';

import { STEPS } from 'pages/PriceSeries/Update/utils';

import { PriceSeriesFrequencyTable } from './components';

import {
  DAILY,
  WEEKLY,
  MONTHLY,
  QUARTERLY,
  FILTERS as LAYOUT_FILTERS,
  FREQUENCIES
} from './utils';
import { HYDROGEN_SLUG } from 'domainConstants';
import { useFetchPriceSeriesForFrequency } from './components/PriceSeriesFrequencyTable/hooks';

function PriceSerieUpdate({ user, crumbs, history }) {
  const { productSlug } = useParams();

  const { filters, filterBy, clear } = useFilters({ history });

  const disabled = productSlug === HYDROGEN_SLUG;

  const {
    loading: dailyPriceSeriesLoading,
    priceSeriesHeaders: dailyPriceSeriesHeaders,
    priceSeriesDataRows: dailyPriceSeriesDataRows,
    fetchPriceSeries: fetchDailyPriceSeries
  } = useFetchPriceSeriesForFrequency({
    frequency: DAILY,
    filters,
    productSlug
  });

  const {
    loading: weeklyPriceSeriesLoading,
    priceSeriesHeaders: weeklyPriceSeriesHeaders,
    priceSeriesDataRows: weeklyPriceSeriesDataRows,
    fetchPriceSeries: fetchWeeklyPriceSeries
  } = useFetchPriceSeriesForFrequency({
    frequency: WEEKLY,
    filters,
    productSlug
  });

  const {
    loading: monthlyPriceSeriesLoading,
    priceSeriesHeaders: monthlyPriceSeriesHeaders,
    priceSeriesDataRows: monthlyPriceSeriesDataRows,
    fetchPriceSeries: fetchMonthlyPriceSeries
  } = useFetchPriceSeriesForFrequency({
    frequency: MONTHLY,
    filters,
    productSlug
  });

  const {
    loading: quarterlyPriceSeriesLoading,
    priceSeriesHeaders: quarterlyPriceSeriesHeaders,
    priceSeriesDataRows: quarterlyPriceSeriesDataRows,
    fetchPriceSeries: fetchQuarterlyPriceSeries
  } = useFetchPriceSeriesForFrequency({
    frequency: QUARTERLY,
    filters,
    productSlug
  });

  const commonProps = {
    user,
    filters,
    filterBy,
    disabled
  };

  const frequencies = _.castArray(filters['frequency'] || FREQUENCIES).map(
    _.toLower
  );

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content maxWidth crumbs={crumbs}>
          <Header>Price Series Update</Header>

          <SharedFilters
            filters={filters}
            filterBy={filterBy}
            clearFilters={clear}
            layout={LAYOUT_FILTERS(productSlug)}
          />

          {frequencies.includes(DAILY) && (
            <PriceSeriesFrequencyTable
              {...commonProps}
              frequency={DAILY}
              steps={STEPS[DAILY.toLowerCase()]}
              loading={dailyPriceSeriesLoading}
              priceSeriesHeaders={dailyPriceSeriesHeaders}
              priceSeriesDataRows={dailyPriceSeriesDataRows}
              fetchPriceSeries={fetchDailyPriceSeries}
              refetchOtherPrices={() => {
                fetchWeeklyPriceSeries();
                fetchMonthlyPriceSeries();
                fetchQuarterlyPriceSeries();
              }}
            />
          )}

          {frequencies.includes(WEEKLY) && (
            <PriceSeriesFrequencyTable
              {...commonProps}
              frequency={WEEKLY}
              steps={STEPS[WEEKLY.toLowerCase()]}
              loading={weeklyPriceSeriesLoading}
              priceSeriesHeaders={weeklyPriceSeriesHeaders}
              priceSeriesDataRows={weeklyPriceSeriesDataRows}
              fetchPriceSeries={fetchWeeklyPriceSeries}
              refetchOtherPrices={() => {
                fetchDailyPriceSeries();
                fetchMonthlyPriceSeries();
                fetchQuarterlyPriceSeries();
              }}
            />
          )}

          {frequencies.includes(MONTHLY) && (
            <PriceSeriesFrequencyTable
              {...commonProps}
              frequency={MONTHLY}
              steps={STEPS[MONTHLY.toLowerCase()]}
              loading={monthlyPriceSeriesLoading}
              priceSeriesHeaders={monthlyPriceSeriesHeaders}
              priceSeriesDataRows={monthlyPriceSeriesDataRows}
              fetchPriceSeries={fetchMonthlyPriceSeries}
              refetchOtherPrices={() => {
                fetchDailyPriceSeries();
                fetchWeeklyPriceSeries();
                fetchQuarterlyPriceSeries();
              }}
            />
          )}

          {frequencies.includes(QUARTERLY) && (
            <PriceSeriesFrequencyTable
              {...commonProps}
              frequency={QUARTERLY}
              steps={STEPS[QUARTERLY.toLowerCase()]}
              loading={quarterlyPriceSeriesLoading}
              priceSeriesHeaders={quarterlyPriceSeriesHeaders}
              priceSeriesDataRows={quarterlyPriceSeriesDataRows}
              fetchPriceSeries={fetchQuarterlyPriceSeries}
              refetchOtherPrices={() => {
                fetchDailyPriceSeries();
                fetchWeeklyPriceSeries();
                fetchMonthlyPriceSeries();
              }}
            />
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSerieUpdate)
);
