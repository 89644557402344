export const SUBSCRIPTIONS = {
  KS: [1],
  'KS Live': [6],
  KPM: [10],
  CSI: [3],
  KSW: [4, 5],
  SEASM: [11, 12],
  'Prices Data Feed': [8],
  Hydrogen: [49]
};

export const EVENT_TYPES = {
  comments: { slug: 'comments', title: 'Comments' },
  feedback: { slug: 'feedback', title: 'Feedback' },
  trial: { slug: 'trial-request', title: 'Subscription Trial' },
  subscription: { slug: 'subscription-request', title: 'Subscription' },
  unsubscription: {
    slug: 'unsubscription-request',
    title: 'Unsubscription requests'
  },
  marketReports: { slug: 'market-report', title: 'Market Report' },
  courses: { slug: 'courses', title: 'Events' },
  faqSubmission: { slug: 'faq-submission', title: 'FAQ Submissions' }
};
