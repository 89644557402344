import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import {
  WeeklySteelList,
  SEASMEditionList,
  SteelPulseMenaEditionList,
  SteelIntelligenceIndiaEditionList,
  SteelIntelligenceChinaEditionList
} from 'pages';

import {
  Field,
  FormRow,
  AdminSelect,
  YesNoSelect,
  DateField,
  DateTimeField,
  InputField,
  FileField,
  RichTextAreaField,
  TextAreaField,
  CreateButtonInRow,
  Loader,
  UnsavedFormPrompt,
  ErrorsAndCreateButtonInRow
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  weeklySteelCreateSchema,
  AdditionalValidationCreateSchema
} from './schema';

class WeeklySteelCreateForm extends React.Component {
  state = {
    subscriberText: '',
    subscriberHtml: '',

    chasingText: '',
    chasingHtml: '',

    trialText: '',
    trialHtml: '',
    isLoading: true
  };

  async componentDidMount() {
    const { success, data } = await this.props.editionTemplatesSdk();

    if (success) {
      this.setState({
        subscriberText: data.subscriber_text,
        subscriberHtml: data.subscriber_html,

        chasingText: data.chasing_text,
        chasingHtml: data.chasing_html,

        trialText: data.trial_text,
        trialHtml: data.trial_html,
        isLoading: false
      });
    }
  }

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await this.props.editionCreateSdk(data);

    if (success) {
      notifySuccess(`${this.props.title} added successfully`);

      const editionMap = {
        'Weekly Steel': WeeklySteelList,
        'SEASM report': SEASMEditionList,
        'Steel Pulse MENA report': SteelPulseMenaEditionList,
        'Steel Intelligence China report': SteelIntelligenceChinaEditionList,
        'Steel Intelligence India report': SteelIntelligenceIndiaEditionList
      };

      const successUrl = editionMap[this.props.title] || WeeklySteelList;

      this.props.history.push(pageUrl(successUrl));
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const {
      subscriberHtml,
      subscriberText,
      chasingHtml,
      chasingText,
      trialHtml,
      trialText
    } = this.state;

    const { enableXlsAttachments } = this.props;

    const initialValues = {
      issueDate: null,
      issueNumber: '',
      title: '',
      datetime: null,
      editor: null,
      marketer: null,
      editorApproved: false,
      marketingApproved: false,

      cover: null,
      pdf: null,
      samplePdf: null,
      xls: null,
      sampleXls: null,

      lead: '',
      text: '',

      trialSubject: '',
      trialHtml,
      trialText,
      subscriberSubject: '',
      subscriberHtml,
      subscriberText,
      chasingSubject: '',
      chasingHtml,
      chasingText
    };

    let validationSchema = weeklySteelCreateSchema;
    if (enableXlsAttachments) {
      validationSchema = validationSchema.concat(
        AdditionalValidationCreateSchema
      );
    }

    return (
      <>
        {this.state.isLoading && <Loader />}
        {!this.state.isLoading && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}>
            {({ handleSubmit, isSubmitting, dirty, touched, errors }) => (
              <Form>
                <UnsavedFormPrompt when={dirty && !isSubmitting} />
                <FormRow>
                  <Field
                    required
                    width={10}
                    name="title"
                    component={InputField}
                    label="Title"
                  />
                  <Form.Field width={6}>
                    <CreateButtonInRow
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    />
                  </Form.Field>
                </FormRow>
                <FormRow>
                  <Field
                    required
                    width={3}
                    name="issueNumber"
                    component={InputField}
                    label="Issue number"
                  />
                  <Field
                    required
                    width={3}
                    name="issueDate"
                    component={DateField}
                    label="Issue date"
                  />
                  <Field
                    required
                    width={4}
                    name="datetime"
                    component={DateTimeField}
                    label="Date to go Out"
                  />
                </FormRow>

                <FormRow>
                  <Field
                    required
                    width={5}
                    name="editor"
                    component={AdminSelect}
                    label="Editor"
                  />

                  <Field
                    width={3}
                    name="editorApproved"
                    component={YesNoSelect}
                    label="Editor Approved"
                  />

                  <Field
                    required
                    width={5}
                    name="marketer"
                    component={AdminSelect}
                    label="Marketer"
                  />
                  <Field
                    width={3}
                    name="marketingApproved"
                    component={YesNoSelect}
                    label="Marketer Approved"
                  />
                </FormRow>
                <FormRow widths="equal">
                  <Field
                    required
                    name="cover"
                    component={FileField}
                    label="Cover Image"
                  />

                  <Field
                    required
                    name="pdf"
                    component={FileField}
                    label="PDF"
                  />

                  <Field
                    required
                    name="samplePdf"
                    component={FileField}
                    label="Sample PDF"
                  />
                  {enableXlsAttachments && (
                    <>
                      <Field
                        required
                        name="xls"
                        component={FileField}
                        label="XLS"
                      />
                      <Field
                        required
                        name="sampleXls"
                        component={FileField}
                        label="Sample XLS"
                      />
                    </>
                  )}
                </FormRow>

                <Field
                  required
                  name="lead"
                  component={RichTextAreaField}
                  label="Lead"
                />

                <Field
                  required
                  name="text"
                  component={RichTextAreaField}
                  label="Text"
                />
                <Field
                  width={6}
                  name="trialSubject"
                  component={InputField}
                  label="Trials Subject"
                />
                <Field
                  name="trialHtml"
                  component={RichTextAreaField}
                  label="Trials HTML"
                  config={{
                    height: '400px'
                  }}
                />

                <Field
                  name="trialText"
                  component={TextAreaField}
                  label="Trials Text"
                />

                <Field
                  width={6}
                  name="subscriberSubject"
                  component={InputField}
                  label="Subscribers Subject"
                />
                <Field
                  name="subscriberHtml"
                  component={RichTextAreaField}
                  label="Subscribers HTML"
                  config={{
                    height: '400px'
                  }}
                />

                <Field
                  name="subscriberText"
                  component={TextAreaField}
                  label="Subscribers Text"
                />

                <Field
                  width={6}
                  name="chasingSubject"
                  component={InputField}
                  label="Chasing Subject"
                />
                <Field
                  name="chasingHtml"
                  component={RichTextAreaField}
                  label="Chasing HTML"
                  config={{
                    height: '400px'
                  }}
                />

                <Field
                  name="chasingText"
                  component={TextAreaField}
                  label="Chasing Text"
                />
                <ErrorsAndCreateButtonInRow
                  touched={touched}
                  errors={errors}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }
}

export default withRouter(WeeklySteelCreateForm);
